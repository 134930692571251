import { useAuthStore } from '~common/stores/auth'
import useWhitelabelStore from '~/stores/whiteLabel'
import { computed } from 'vue'
import type { IMenuItem } from '~/models/NavigationList'

import useWhiteLabel from '~/composables/useWhiteLabel'
import { useI18n } from '~/common/src/composables/useI18n'
import useWhiteLabelStore from '~/stores/whiteLabel'

const buccketUrl = import.meta.env.PUBLIC_BUCCKET_URL

export default () => {
  const authStore = useAuthStore()
  const whiteLabelStore = useWhitelabelStore()
  const whiteLabelStoreStore = useWhiteLabelStore()

  const { activeWhiteLabel } = useWhiteLabel()
  const { t } = useI18n()

  const currentWl = computed(
    () => activeWhiteLabel.value || whiteLabelStore.wlItems[0]
  )

  const globalMenuList = computed<IMenuItem[]>(() => {
    const list: IMenuItem[] = []

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/users',
        defaultPath: '/users',
        title: t('admin.navigation.global.users'),
        icon: 'users'
      })

      list.push({
        rootPath: '/operations',
        defaultPath: '/operations',
        title: t('admin.navigation.global.operations'),
        icon: 'operations'
      })

      list.push({
        rootPath: '/settings',
        defaultPath: '/settings/app',
        title: t('admin.navigation.global.settings'),
        icon: 'settings'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_investment ||
      authStore.adminPermissions.has_write_access_to_investment
    ) {
      list.push({
        rootPath: '/investment',
        defaultPath: '/investment',
        title: t('admin.navigation.global.investment'),
        icon: 'investment'
      })
    }

    list.push({
      rootPath: '/payment-providers',
      defaultPath: '/payment-providers',
      title: t('admin.navigation.global.paymentProviders'),
      icon: 'com:user-group-outline'
    })

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/clearing',
        defaultPath: '/clearing/balances',
        title: t('admin.navigation.global.clearing'),
        icon: 'scales'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_analytics ||
      authStore.adminPermissions.has_read_access_to_balances ||
      authStore.adminPermissions.has_write_access_to_balances
    ) {
      list.push({
        rootPath: '/analytics',
        defaultPath: '/analytics/balances/users',
        title: t('admin.navigation.global.analytics'),
        icon: 'analytics'
      })
    }

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/admins',
        defaultPath: '/admins/list',
        title: t('admin.navigation.global.admins'),
        icon: 'admin'
      })

      list.push({
        rootPath: '/openapi',
        defaultPath: '/openapi',
        title: t('admin.navigation.global.openapi'),
        icon: 'com:uikit'
      })

      list.push({
        rootPath: '/wl/create',
        defaultPath: '/wl/create',
        title: t('admin.navigation.global.wlCreate'),
        icon: 'com:deposit'
      })
    }

    return list
  })

  const wlMenuList = computed(() => {
    const list: IMenuItem[] = []

    if (
      authStore.adminPermissions.has_read_access_to_users ||
      authStore.adminPermissions.has_write_access_to_users ||
      authStore.adminPermissions.has_read_access_to_kyc ||
      authStore.adminPermissions.has_write_access_to_kyc ||
      authStore.adminPermissions.has_read_access_to_kyc_kgz ||
      authStore.adminPermissions.has_write_access_to_kyc_kgz ||
      authStore.adminPermissions.has_read_access_to_own_referrals_users
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/users`,
        defaultPath: `/wl/${currentWl.value?.id}/users`,
        title: t('admin.navigation.wl.users'),
        icon: 'users'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_ledgers ||
      authStore.adminPermissions.has_read_access_to_withdrawals ||
      authStore.adminPermissions.has_write_access_to_withdrawals ||
      authStore.adminPermissions.has_read_access_to_swift ||
      authStore.adminPermissions.has_read_access_to_trades ||
      authStore.adminPermissions.has_write_access_to_swift ||
      authStore.adminPermissions.has_read_access_to_visa_withdrawals ||
      authStore.adminPermissions.has_write_access_to_visa_withdrawals ||
      authStore.adminPermissions.has_read_access_to_p2p ||
      authStore.adminPermissions.has_write_access_to_p2p ||
      authStore.adminPermissions.has_read_access_to_own_referrals_ledgers ||
      authStore.adminPermissions.has_read_access_to_bank_transfers ||
      authStore.adminPermissions.has_write_access_to_bank_transfers
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/operations`,
        defaultPath: `/wl/${currentWl.value?.id}/operations`,
        title: t('admin.navigation.wl.operations'),
        icon: 'operations'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_wl_settings ||
      authStore.adminPermissions.has_write_access_to_wl_settings ||
      authStore.adminPermissions.has_read_access_to_options ||
      authStore.adminPermissions.has_write_access_to_options ||
      authStore.adminPermissions.has_read_access_to_deposits ||
      authStore.adminPermissions.has_write_access_to_deposits ||
      authStore.adminPermissions.has_read_access_to_borrowings ||
      authStore.adminPermissions.has_write_access_to_borrowings ||
      authStore.adminPermissions.has_read_access_to_wl_services
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/settings`,
        defaultPath: `/wl/${currentWl.value?.id}/settings`,
        title: t('admin.navigation.wl.settings'),
        icon: 'com:settings'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_investment ||
      authStore.adminPermissions.has_write_access_to_investment
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/investment`,
        defaultPath: `/wl/${currentWl.value?.id}/investment/products`,
        title: t('admin.navigation.wl.investment'),
        icon: 'investment'
      })
    }

    // TODO: права на Клиринг
    list.push({
      rootPath: `/wl/${currentWl.value?.id}/clearing`,
      defaultPath: `/wl/${currentWl.value?.id}/clearing/balances`,
      title: t('admin.navigation.wl.clearing'),
      icon: 'scales'
    })

    if (
      authStore.adminPermissions.has_read_access_to_operator ||
      authStore.adminPermissions.has_write_access_to_operator ||
      authStore.adminPermissions.has_read_access_to_bank_transfers ||
      authStore.adminPermissions.has_write_access_to_bank_transfers
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/operator`,
        defaultPath: `/wl/${currentWl.value?.id}/operator`,
        title: t('admin.navigation.wl.operator'),
        icon: 'operator'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_contracts ||
      authStore.adminPermissions.has_write_access_to_contracts
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/documents`,
        defaultPath: `/wl/${currentWl.value?.id}/documents/contracts`,
        title: t('admin.navigation.wl.documents'),
        icon: 'com:documents'
      })
    }

    if (
      authStore.adminPermissions.is_super_admin ||
      authStore.adminPermissions.has_read_access_to_admins
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/admins`,
        defaultPath: `/wl/${currentWl.value?.id}/admins/list`,
        title: t('admin.navigation.wl.admins'),
        icon: 'admin'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_analytics ||
      authStore.adminPermissions.has_read_access_to_balances ||
      authStore.adminPermissions.has_write_access_to_balances
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/analytics`,
        defaultPath: `/wl/${currentWl.value?.id}/analytics/balances/users`,
        title: t('admin.navigation.wl.analytics'),
        icon: 'analytics'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_emails ||
      authStore.adminPermissions.has_write_access_to_emails
    ) {
      list.push({
        rootPath: `/wl/${currentWl.value?.id}/emails`,
        defaultPath: `/wl/${currentWl.value?.id}/emails`,
        title: t('admin.navigation.wl.emails'),
        icon: 'email-config'
      })
    }

    list.push({
      rootPath: `https://${currentWl.value?.domain}`,
      defaultPath: `https://${currentWl.value?.domain}`,
      title: t('admin.navigation.wl.app'),
      icon: 'dashboard',
      external: true
    })

    return list
  })

  const wlMenuItems = computed<IMenuItem[]>(() => {
    return whiteLabelStoreStore.wlItems.map(item => ({
      ...item,
      title: item.short_name,
      rootPath: `/wl/${item.id}`,
      defaultPath: `/wl/${item.id}/settings/general`,
      iconUrl: `${buccketUrl}/wl/${item.domain}/logos/footer.svg`
    }))
  })

  const activeWhiteLabelMenuItem = computed(() => {
    return wlMenuItems.value.find(
      ({ title }) => title === currentWl.value?.short_name
    )
  })

  return {
    globalMenuList,
    wlMenuList,
    currentWl,
    wlMenuItems,
    activeWhiteLabelMenuItem
  }
}
